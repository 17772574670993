var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { OeReportSubType, OeReportType } from "./enum";
import { useUser } from './useUser';
import { useQuery } from './useQuery';
import { useDevice } from './useDevice';
import { useReport } from './useReport';
import { useStrategy } from './useStrategy';
import { getParamsFromUrl, getUrlFromParams } from './utils';
const { userInfo } = useUser();
const { query } = useQuery();
const { deviceInfo } = useDevice();
const { reportExp } = useReport();
const { strategy } = useStrategy();
const _doReport = (params) => {
    let url = HAA_OE_URL + getUrlFromParams(params);
    axios({
        url: url,
        method: 'get',
    }).catch((err) => {
        reportExp('OE_REPORT_CATCH', err);
    });
};
const oeReport = (etype, subtype, action_value, action_value2, duration, app_x, app_y, action_value3) => {
    if (strategy.oe_log_enable == 0)
        return;
    let oeKey = Object.keys(strategy.oe_log_config).find(item => +item == subtype);
    if (oeKey && strategy.oe_log_config[oeKey] == 0)
        return;
    let { AppName, Model, OSType, DeviceType, Brand, OsVersion } = deviceInfo;
    let { uuid, sessionId, appLaunchId } = userInfo;
    let { channel, subchannel, key, source, request_id, ad_id } = query;
    let params = {
        pid: PACKAGE_NAME,
        key: key,
        brand: Brand,
        channel: channel,
        subchannel: subchannel,
        os: OSType,
        os_version: OsVersion,
        model: Model,
        net_type: appLaunchId,
        progress: new Date().getTime(),
        app_version: APP_VERSION_NAME,
        app_uri: encodeURIComponent(source),
        request_id: request_id || '',
        ad_id: ad_id || '',
        app_type: APP_TYPE,
        uuid: uuid || "",
        launch_id: sessionId || '',
        lang: window.navigator.language,
        width: window.screen.width,
        height: window.screen.height,
        etype: etype,
        referer: subtype,
        action_value: action_value || '',
        action_value2: action_value2 || '',
        duration: duration || '',
        app_x: app_x || '',
        app_y: app_y || '',
        action_value3: action_value3 || '',
    };
    if (HAA_OE_KEY_PID)
        params[HAA_OE_KEY_PID] = PACKAGE_NAME;
    if (HAA_OE_KEY_KEY)
        params[HAA_OE_KEY_KEY] = key;
    if (HAA_OE_KEY_ETYPE)
        params[HAA_OE_KEY_ETYPE] = etype;
    _doReport(params);
};
// ------------------- 停留上报 ---------------------
let _usingTimer = null;
let _usingDelta = [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 300, 600, 900, 1200, 1800];
let _usingIndex = 0;
let _usingPass = 0;
let _usingStart = 0;
const SESSION_KEY = 'HAA_STAY_SESSION';
const clearUsingTimer = function () {
    if (_usingTimer) {
        clearTimeout(_usingTimer);
        _usingTimer = null;
    }
    _usingIndex = 0;
    _usingPass = 0;
    _usingStart = 0;
};
const stopUsingTimer = function () {
    clearTimeout(_usingTimer);
    _usingPass += Math.max(new Date().getTime() - _usingStart, 0);
    _usingTimer = null;
    // console.error('暂停', _usingIndex, _usingPass, _usingStart)
};
const startUsingTimer = function () {
    // console.error('启动', _usingIndex, _usingPass, _usingStart)
    // 避免重复开始
    if (_usingTimer)
        return;
    // 从session里读取初始值，防止页面刷新导致上报不连续
    try {
        let cacheParamsString = sessionStorage.getItem(SESSION_KEY);
        if (cacheParamsString) {
            let cacheParams = JSON.parse(cacheParamsString);
            _usingIndex = cacheParams._usingIndex;
            _usingPass = cacheParams._usingPass;
            _usingStart = cacheParams._usingStart;
        }
    }
    catch (error) {
        // 解析缓存数据失败
    }
    // 全完事儿了
    if (!_usingDelta[_usingIndex]) {
        return clearUsingTimer();
    }
    let delta = _usingIndex == 0 ? _usingDelta[_usingIndex] : (_usingDelta[_usingIndex] - _usingDelta[_usingIndex - 1]);
    delta *= 1000;
    if (_usingPass > 0) {
        delta = Math.max(delta - _usingPass, 0);
        // _usingPass = 0;
    }
    if (delta == 0) {
        return clearUsingTimer();
    }
    _usingStart = new Date().getTime();
    // console.error('----------开始delta = ' + delta);
    _usingTimer = setTimeout(() => {
        // console.error(`停留上报，当前index=${_usingIndex},停留${_usingDelta[_usingIndex]}`);
        oeReport(OeReportType.Action, OeReportSubType.Stay, _usingDelta[_usingIndex] + '');
        _usingIndex++;
        _usingTimer = null;
        _usingPass = 0;
        let cacheParams = {
            _usingIndex: _usingIndex,
            _usingPass: _usingPass,
            _usingStart: _usingStart,
        };
        sessionStorage.setItem(SESSION_KEY, JSON.stringify(cacheParams));
        startUsingTimer();
    }, delta);
};
/** 日启频次上报 */
const LAUNCH_CONFIG_KEY = "LaunchConfig";
const dailyLaunchReport = function () {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            let config = localStorage.getItem(LAUNCH_CONFIG_KEY);
            const curDateString = new Date().toDateString();
            if (!config) {
                config = { latest: curDateString, times: 1 };
            }
            else {
                config = JSON.parse(config);
                if (config) {
                    if (config.latest == curDateString) {
                        config.times && config.times++;
                    }
                    else {
                        config.times = 1;
                        config.latest = curDateString;
                    }
                }
            }
            localStorage.setItem(LAUNCH_CONFIG_KEY, JSON.stringify(config));
            config && config.times && oeReport(OeReportType.Action, OeReportSubType.DailyLaunch, config.times);
        }
        catch (e) {
            reportExp('DailyLaunchCatch', e);
        }
    });
};
/** 页面深度上报 */
// 页面深度
let _calculatedPageCount = { count: 0 };
class PageDeep {
    constructor() {
        this._initTime = 0;
        this._lastShowTime = 0;
        this._heartStartTime = 0;
        this._heartPassTime = 0;
        this._heartTimes = 0;
        this._timer = -1;
        this._isSecondPage = false;
    }
    init(isSecondPage) {
        this._isSecondPage = isSecondPage || false;
        this._initTime = new Date().getTime();
        this.updateTimer();
    }
    show() {
        this._lastShowTime = new Date().getTime();
        this.updateTimer();
    }
    hide() {
        if (this._timer >= 0) {
            if (this._heartStartTime > 0) {
                this._heartPassTime = this._heartPassTime + (+new Date()) - this._heartStartTime;
            }
            clearTimeout(this._timer);
            this._timer = -1;
        }
    }
    destroy() {
        if (this._timer >= 0) {
            clearTimeout(this._timer);
            this._timer = -1;
        }
    }
    updateTimer() {
        if (this._heartTimes * 1000 == 1000)
            return;
        const curTime = new Date().getTime();
        if (this._timer >= 0)
            return;
        this._heartStartTime = curTime;
        let remainTime = 1000 - this._heartPassTime;
        remainTime = remainTime <= 0 ? 1000 : remainTime;
        this._timer = setTimeout(() => {
            this._heartTimes++;
            _calculatedPageCount.count++;
            if (this._isSecondPage && _calculatedPageCount.count < 2) {
                _calculatedPageCount.count = 2;
            }
            oeReport(OeReportType.Action, OeReportSubType.PageDeep, _calculatedPageCount.count + '');
            this._timer = -1;
            this._heartPassTime = 0;
        }, remainTime);
    }
}
/** 互动确认后，打开新窗口 */
const openWindow = (newParams) => {
    let params = getParamsFromUrl(window.location.href);
    // 有策略控制是否打开新窗口
    if (strategy.guide_open_window == 0) {
        const { uuid, sessionId } = userInfo;
        if (newParams)
            Object.assign(params, newParams);
        if (!params.uuid)
            params.uuid = uuid;
        if (!params.launchid)
            params.launchid = sessionId;
        let url = getUrlFromParams(params);
        let baseUrl = window.location.origin;
        let pathname = window.location.pathname;
        if (pathname.indexOf('/dev') == -1)
            pathname = '/';
        return window.open(baseUrl + pathname + url, '_self');
    }
    else {
        return false;
    }
};
export function useOeReport() {
    return { oeReport, startUsingTimer, stopUsingTimer, dailyLaunchReport, _calculatedPageCount, PageDeep, openWindow };
}
