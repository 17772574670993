import axios from 'axios';
import { reactive } from 'vue';
import { useQuery } from './useQuery';
import { useDevice } from './useDevice';
import { randomString } from './utils';
import { AesDecode, AesEncode } from './useEncrypt';
import { useReport } from './useReport';
const { query } = useQuery();
const { deviceInfo } = useDevice();
const { reportExp } = useReport();
import bus from '../bus';
/** 超时时间（秒） */
const DELAY_TIME = 1;
/** 默认策略，当请求失败 或 超时时，使用默认策略 */
const defaultStrategy = {
    status: 2,
    is_ad_shield: 0,
    banner_refresh_time: 0,
    ad_req_max: -1,
    guide_click_enable: -1,
    guide_timeout: -1,
    // vivo渠道默认值为0：打开新窗口，其他渠道默认值1：不打开新窗口
    guide_open_window: query.channel == 'vivo' ? 0 : 1,
    native_request: 0,
    oe_log_enable: 1,
    oe_log_config: {},
    guide_used_click: (query.channel.toLowerCase() == 'vivo' && (query.request_id == '__REQUESTID__' && query.ad_id == '__ADID__')) ? 3 : 0,
    h5_ad_space_config: {},
};
/** 最终使用的策略对象 */
const strategy = reactive({
    status: 0,
    is_ad_shield: 0,
    banner_refresh_time: 0,
    ad_req_max: -1,
    guide_click_enable: -1,
    guide_timeout: -1,
    // vivo渠道默认值为0：打开新窗口，其他渠道默认值1：不打开新窗口
    guide_open_window: query.channel == 'vivo' ? 0 : 1,
    native_request: 0,
    oe_log_enable: 1,
    oe_log_config: {},
    guide_used_click: (query.channel.toLowerCase() == 'vivo' && (query.request_id == '__REQUESTID__' && query.ad_id == '__ADID__')) ? 3 : 0,
    h5_ad_space_config: {},
});
/** 记录广告是否达到请求频次 */
const adControl = reactive({
    ad_req_limit: false,
});
const getStrategy = () => {
    const { channel, subchannel, key } = query;
    const params = {
        app: {
            reqid: randomString(16) + new Date().getTime(),
            pid: PACKAGE_NAME,
            channel: channel,
            sub_channel: subchannel,
            key: key,
            brand: deviceInfo.Brand,
            version: parseInt(APP_VERSION_CODE),
        }
    };
    return new Promise(resolve => {
        /** 超时，直接使用默认策略 */
        setTimeout(() => {
            if (strategy.status != 0)
                return;
            Object.assign(strategy, defaultStrategy);
            reportExp('StrategyOvertime');
            bus.emit('strategy', strategy);
            resolve(strategy);
        }, DELAY_TIME * 1000);
        axios.post(HAA_STRATEGY_URL, AesEncode(JSON.stringify(params)), { responseType: 'json' }).then(res => {
            if (strategy.status != 0)
                return;
            /** 如果没超时，但结果有问题，直接使用默认策略 */
            if (!res || !res.data || !res.data.data) {
                Object.assign(strategy, defaultStrategy);
                reportExp('StrategyNoData', JSON.stringify(res));
                return resolve(strategy);
            }
            let strategyInfo = JSON.parse(AesDecode(res.data.data));
            Object.assign(strategy, strategyInfo);
            if (query.channel.toLowerCase() == 'vivo' && (query.request_id == '__REQUESTID__' && query.ad_id == '__ADID__')) {
                strategy.guide_click_enable = -1;
                strategy.guide_used_click = 3;
            }
            strategy.status = 1;
            bus.emit('strategy', strategy);
            resolve(strategy);
        });
    });
};
export function useStrategy() {
    return { strategy, adControl, defaultStrategy, getStrategy };
}
